import Lottie from "react-lottie-player";
import React, { useState, useEffect } from "react";

import styles from "./featured-links.module.css";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import MenuSelector from "../images/MenuSelector.svg";

const FeaturedLinks = ({ links, lottieFile, text }) => {
  const [animationData, setAnimationData] = useState(null);

  const defaultOptions = {
    loop: true,
  };

  useEffect(() => {
    if (lottieFile) {
      const fetchData = async () => {
        const response = await fetch(lottieFile.asset.url);
        let responseJson = await response.json();

        setAnimationData(responseJson);
      };
      fetchData();
    }
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {links.map(({ url, label, newTab }, index) => (
          <Link key={index} href={url} target={newTab ? "_blank" : ""} className={cn(styles.link)}>
            <img src={MenuSelector} alt="selector" className={styles.selector} />
            <div className={styles.linkInner}>{label}</div>
          </Link>
        ))}
      </div>
      <div className={styles.lottieWrapper}>
        <div className={styles.lottie}>
          {lottieFile && animationData && (
            <Lottie {...defaultOptions} play={true} animationData={animationData} />
          )}
        </div>
        {text && <div className={styles.lottieText}>{text}</div>}
      </div>
    </div>
  );
};

export default FeaturedLinks;
